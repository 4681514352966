import React from "react";
import Typography from "@mui/material/Typography";

import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";

const TermsModal = ({ open, onClose }) => (
  <Dialog open={open} onClose={onClose} maxWidth="md">
    <DialogTitle> Términos y condiciones de uso </DialogTitle>

    <DialogContent>
      <DialogContentText>
        <Typography variant="body1" gutterBottom>
          Las presentes Condiciones y Términos de uso generales (en adelante,
          las Condiciones y Términos de Uso) regulan el acceso y el uso del
          sitio de Internet CREATE & SHARE (en adelante, la WEB) que LEMONLAND
          pone a disposición de los Usuarios de Internet (en adelante, el
          Usuario o los Usuarios). Por favor, lee detenidamente estos Términos,
          así como nuestra Política de Privacidad.
        </Typography>
        <Typography variant="body1" gutterBottom>
          La utilización de CREATE & SHARE supone la aceptación plena por el
          Usuario de todas las Condiciones y Términos de Uso vigentes en cada
          momento en que el Usuario acceda al mismo, así como de nuestra
          Política de Privacidad. Por lo que si el Usuario no está de acuerdo
          con cualquiera de las condiciones aquí establecidas deberá abstenerse
          de usar la WEB.
        </Typography>

        <Typography variant="h6" gutterBottom>
          1. Datos de identificación
        </Typography>
        <Typography variant="body1" gutterBottom>
          CREATE & SHARE, es un producto de LEMONLAND, S.L. con domicilio social
          Príncipe de Vergara, 112 (edificio Utopicus), C.P. 28002, Madrid; NIF
          B01787886; contacto{" "}
          <a href="mailto:hello@lemonland.es">hello@lemonland.es</a>
        </Typography>
        <Typography variant="h6" gutterBottom>
          2. Objeto
        </Typography>
        <Typography variant="body1" gutterBottom>
          La WEB es una herramienta que permite crear contenido de forma
          autónoma en forma de píldoras o páginas web únicas que pueden ser
          publicadas online de manera abierta al público y compartidas mediante
          envío de la url. (en adelante, la Herramienta).
        </Typography>

        <Typography variant="h6" gutterBottom>
          3. Consentimiento
        </Typography>
        <Typography variant="body1" gutterBottom>
          3.1 El acceso a la WEB se encuentra condicionado a la previa lectura y
          aceptación de los términos, condiciones, comunicaciones, advertencias
          y avisos legales contenidos en este documento por los Usuarios. Desde
          el momento en el que los Usuarios entran y usan la WEB están aceptando
          las Condiciones y Términos de Uso. Si algún Usuario no estuviese de
          acuerdo con el contenido o parte del contenido de las presentes
          Condiciones y Términos de Uso deberá abandonar la WEB, no pudiendo
          acceder ni hacer uso de la Herramienta.
        </Typography>
        <Typography variant="body1" gutterBottom>
          3.2 El acceso a la WEB es libre y gratuito en lo que a la creación de
          un máximo de 10 píldoras o páginas web únicas se refiere. La gestión
          completa de todas las funcionalidades de la herramienta y la creación
          de contenido ilimitado sí requiere de una licencia de uso de la
          herramienta que debe ser contratada a través de LEMONLAND.
        </Typography>
        <Typography variant="body1" gutterBottom>
          3.3 El Usuario entiende y acepta que cualquier utilización distinta a
          la autorizada está expresamente prohibida.
        </Typography>
        <Typography variant="body1" gutterBottom>
          3.4 CREATE & SHARE se reserva el derecho de efectuar sin previo aviso
          los cambios y las modificaciones que considere oportunas en la WEB y
          en sus Condiciones y Términos de Uso, así como en su Política de
          Privacidad.
        </Typography>
        <Typography variant="h6" gutterBottom>
          4. Derechos y obligaciones de los usuarios{" "}
        </Typography>

        <Typography variant="body1" gutterBottom>
          4.1 El Usuario está obligado a realizar un uso lícito de la WEB de
          acuerdo con la legislación vigente, respetando los derechos de
          propiedad intelectual e industrial de CREATE & SHARE y de cualquier
          tercero.
        </Typography>

        <Typography variant="body1" gutterBottom>
          4.2 El Usuario garantiza que las actividades que realice en la WEB y
          el Contenido se adecuarán a la ley, la moral, el orden público, las
          buenas costumbres, comprometiéndose a que en ningún caso resultarán
          ofensivas para CREATE & SHARE, LEMONLAND o para cualquier otra
          empresa, otros Usuarios de Internet o terceros.
        </Typography>

        <Typography variant="body1" gutterBottom>
          4.3 El Usuario se compromete a no realizar a través de la WEB ninguna
          acción que cause daño o alteraciones del Contenido de la WEB, ni
          entorpezca el funcionamiento de la WEB, sin causar problemas técnicos,
          no transfiriendo elementos portadores de virus informáticos o capaces
          de dañar, interferir o interceptar total o parcialmente la WEB así
          como a no intervenir o alterar el correo electrónico de otros
          Usuarios.
        </Typography>

        <Typography variant="body1" gutterBottom>
          4.4 El Usuario garantiza que cualquier dato que proporcione a CREATE &
          SHARE será verdadero y correcto.
        </Typography>

        <Typography variant="body1" gutterBottom>
          4.5 El uso que los Usuarios puedan hacer de la herramienta (la WEB) se
          realizará por cuenta y riesgo del Usuario.
        </Typography>

        <Typography variant="h6" gutterBottom>
          5. Derechos y obligaciones de CREATE & SHARE
        </Typography>

        <Typography variant="body1" gutterBottom>
          5.1 CREATE & SHARE tiene el derecho de suspender temporalmente y sin
          preaviso la accesibilidad a la WEB, intentando ponerlo previamente en
          conocimiento de los Usuarios, siempre que las circunstancias así se lo
          permitan.
        </Typography>

        <Typography variant="body1" gutterBottom>
          5.2 CREATE & SHARE no garantiza la veracidad, fiabilidad, precisión,
          oportunidad o conveniencia de la información suministrada a los
          Usuarios por terceras personas.
        </Typography>

        <Typography variant="body1" gutterBottom>
          5.3 CREATE & SHARE no dispone de ningún tipo de control sobre las
          páginas web y/o aplicaciones que no sean suyas y no será responsable
          de su contenido ni de su uso. Cuando el Usuario enlace a una página la
          WEB que no sea de CREATE & SHARE, CREATE & SHARE no declara ni insinúa
          que dé su respaldo a dicha página web. Será responsabilidad del
          Usuario adoptar las previsiones necesarias para salvaguardarse a sí
          mismo y a los sistemas de su ordenador contra virus u otro contenido
          perjudicial o dañino. CREATE & SHARE rechaza cualquier tipo de
          responsabilidad por perjuicios resultantes del uso por parte del
          Usuario de páginas web que no sean de CREATE & SHARE.
        </Typography>

        <Typography variant="h6" gutterBottom>
          6. Garantías y responsabilidad
        </Typography>

        <Typography variant="body1" gutterBottom>
          6.1 CREATE & SHARE no tiene obligación y no controla la utilización
          que los Usuarios hacen de la WEB. Así, CREATE & SHARE no garantiza que
          los Usuarios utilicen la WEB de conformidad con estas Condiciones y
          Términos de Uso, ni que lo hagan de forma diligente, prudente o con
          respeto a la Ley y al orden público.
        </Typography>

        <Typography variant="body1" gutterBottom>
          6.2 El Usuario entiende y acepta que la WEB ha sido creada y
          desarrollada de buena fe por LEMONLAND y la ofrece en su estado actual
          a los Usuarios.
        </Typography>

        <Typography variant="body1" gutterBottom>
          6.3 CREATE & SHARE cumple con la normativa de Protección de Datos y
          dispone de sistemas de privacidad de datos personales que impiden el
          acceso a los mismos por parte de terceros. CREATE & SHARE ha
          establecido todas las medidas de seguridad técnicas y organizativas
          necesarias para garantizar la integridad y confidencialidad de los
          datos personales facilitados por el Usuario, como se detalla en su
          Política de Privacidad.
        </Typography>

        <Typography variant="body1" gutterBottom>
          6.4 No obstante, CREATE & SHARE no controla ni garantiza la ausencia
          de virus ni de otros elementos en la WEB que puedan producir
          alteraciones en el sistema informático (software y hardware) del
          Usuario o en los documentos electrónicos y ficheros almacenados en su
          sistema informático, a pesar de haber adoptado todas las medidas
          tecnológicas necesarias para evitarlo. CREATE & SHARE se exime de
          cualquier responsabilidad por los daños y perjuicios de toda
          naturaleza que puedan deberse a la presencia de virus en el Contenido
          que puedan producir alteraciones en el sistema informático, documentos
          electrónicos, ficheros, etc.
        </Typography>

        <Typography variant="h6" gutterBottom>
          7. Enlaces y vínculos
        </Typography>

        <Typography variant="body1" gutterBottom>
          7.1 Los Usuarios no podrán establecer ningún vínculo y/o enlace a la
          WEB desde cualquier otra la WEB sin el previo y expreso consentimiento
          de CREATE & SHARE, salvo que se indique lo contrario en las presentes
          Condiciones y Términos de uso.
        </Typography>

        <Typography variant="body1" gutterBottom>
          7.2 Si la WEB contuviese vínculos o enlaces con otros sitios web no
          gestionados por CREATE & SHARE o LEMONLAND, CREATE & SHARE manifiesta
          que no ejerce control alguno sobre los sitios web, ni responde o es
          responsable del contenido de los mismos. Los enlaces que la WEB
          pudiera contener se ofrecerán, únicamente, a modo de referencias
          informativas, sin ningún tipo de valoración sobre los contenidos,
          servicios o productos ofrecidos desde los mismos.
        </Typography>

        <Typography variant="body1" gutterBottom>
          7.3 CREATE & SHARE se exonera de toda responsabilidad en relación con
          los servicios prestados por dichos terceros frente a cualesquiera
          reclamaciones de cualquier naturaleza y demandas que pudieran
          interponerse en relación con los mismos.
        </Typography>

        <Typography variant="h6" gutterBottom>
          8. Propiedad intelectual e industrial
        </Typography>

        <Typography variant="body1" gutterBottom>
          8.1 CREATE & SHARE, como titular o cesionaria en exclusiva, ostenta
          todos los derechos de propiedad intelectual e industrial sobre los
          elementos y el Contenido de la WEB.
        </Typography>

        <Typography variant="body1" gutterBottom>
          8.2 Todos los diseños, imágenes, mapas, gráficos, marcas, rótulos,
          signos distintivos o logos de la web, frames, banners, el software y
          sus distintos códigos, fuente y objeto, etc. de la WEB son titularidad
          de LEMONLAND, que es el titular o cesionaria de los derechos de
          explotación de propiedad intelectual e industrial en exclusiva sobre
          los mismos.
        </Typography>

        <Typography variant="body1" gutterBottom>
          8.3 Los logos o signos distintivos de otras empresas, que pudieran
          aparecer en la WEB durante la navegación del Usuario, son titularidad
          de cada una de ellas, disponiendo legítimamente de los derechos de
          explotación sobre los mismos y consintiendo que éstos sean
          incorporados en la WEB.
        </Typography>

        <Typography variant="body1" gutterBottom>
          8.4 Los Usuarios no pueden copiar, modificar, distribuir, transmitir,
          reproducir, publicar, ceder, vender los elementos anteriormente
          mencionados, crear nuevos productos o servicios derivados del
          Contenido y los elementos de la WEB. Los Usuarios se comprometen a
          respetar los derechos de propiedad intelectual e industrial de CREATE
          & SHARE.
        </Typography>

        <Typography variant="body1" gutterBottom>
          8.5 Los Usuarios podrán visualizar el Contenido y los elementos de la
          WEB e incluso imprimirlos, copiarlos y almacenarlos en cualquier
          soporte físico, siempre y cuando sea única y exclusivamente para su
          uso personal y privado, y no comercial, sin que pueda hacerlo
          extensivo a terceras personas. El Usuario deberá abstenerse de eludir,
          manipular, suprimir y alterar cualquier dispositivo de protección o
          sistema de seguridad que estuviera instalado en la WEB.
        </Typography>

        <Typography variant="h6" gutterBottom>
          9. Infracciones
        </Typography>

        <Typography variant="body1" gutterBottom>
          9.1 CREATE & SHARE perseguirá el incumplimiento de las presentes
          Condiciones y Términos de Uso así como cualquier utilización indebida
          de la WEB, ejerciendo todas las acciones civiles y penales que le
          puedan corresponder en derecho.
        </Typography>

        <Typography variant="body1" gutterBottom>
          9.2 CREATE & SHARE pone a disposición de los Usuarios y de terceros un
          sistema de denuncia unilateral, por el que los propios Usuarios
          notificarán a CREATE & SHARE cualquier infracción de sus Condiciones y
          Términos de Uso y Políticas en la siguiente dirección:
          hello@lemonland.es
        </Typography>

        <Typography variant="body1" gutterBottom>
          9.3 CREATE & SHARE hará sus mayores esfuerzos para que el Contenido de
          la WEB no infrinja la Ley y/o tenga carácter pornográfico, xenófobo,
          discriminatorio, racista o que no fomente la violencia, desde el
          momento en el que tenga conocimiento efectivo.
        </Typography>

        <Typography variant="h6" gutterBottom>
          10. Miscelánea
        </Typography>

        <Typography variant="body1" gutterBottom>
          10.1 Las presentes Condiciones y Términos de Uso constituyen el
          acuerdo total entre CREATE & SHARE y el Usuario en lo referente al
          objeto del presente documento y solo se podrán modificar mediante una
          corrección escrita firmada por una persona autorizada de CREATE &
          SHARE, o a través de la publicación de una versión revisada por CREATE
          & SHARE.
        </Typography>

        <Typography variant="body1" gutterBottom>
          10.2. La legislación de algunos países podría no permitir alguno o la
          totalidad de los límites de responsabilidad previstos en las presentes
          Condiciones y Términos de Uso, por lo que dicha legislación podría
          conferir derechos adicionales a los aquí previstos a los Usuarios, que
          CREATE & SHARE respetará cuando se dé el caso.
        </Typography>

        <Typography variant="body1" gutterBottom>
          10.3 Si cualquier cláusula de las presentes Condiciones y Términos de
          Uso fuese declarada total o parcialmente nula o ineficaz afectará tan
          solo a esa disposición o parte de la misma que resulte nula o
          ineficaz, subsistiendo en todo lo demás el resto de Condiciones y
          Términos de Uso y teniéndose tal disposición o la parte de la misma
          que resulte afectada por no puesta salvo que, por resultar esencial a
          las presentes Condiciones y Términos de Uso, hubiese de afectarlas de
          manera integral.
        </Typography>

        <Typography variant="h6" gutterBottom>
          11. Ley aplicable y jurisdicción
        </Typography>

        <Typography variant="body1" gutterBottom>
          11.1 Estas Condiciones y Términos de Uso, así como cualquier relación
          entre los Usuarios y CREATE & SHARE, se regirán por la legislación
          española. En caso de conflicto en la aplicación o interpretación de
          estas Condiciones y Términos de Uso, las partes se someten a los
          Juzgados y Tribunales de Madrid capital.
        </Typography>

        <Typography variant="body1" gutterBottom>
          11.2 Sin perjuicio de lo anterior, cuando el Usuario sea considerado
          como Consumidor podrá reclamar sus derechos como tal en relación con
          las presentes Condiciones y Términos de Uso tanto en España como en su
          Estado Miembro de residencia en la Unión Europea.
        </Typography>
      </DialogContentText>
    </DialogContent>
  </Dialog>
);

export default TermsModal;
